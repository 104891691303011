@import '../../mixins/input.scss';
@import '../../mixins/button.scss';
@import '../../mixins/colors.scss';
.background {
    width:100%;
    height:100%;
    background: $background-main-color;
    // display: flex;
    // flex-direction: column;
    // justify-content:center;
    // align-items: center;
}
.registrationHeader {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 14px;
}
.registrationForms {
    display: flex;
    padding-top: 10%;
    justify-content:center;
    align-items: center;
    flex-direction: column;

}

html {
    width: 100%;
    height: 100%;
}
body {
    margin: 0;
    font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",
    Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,
    "Apple Color Emoji","Segoe UI Emoji",
    "Segoe UI Symbol","Noto Color Emoji";
    // color: $main-color-text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-color: $background-color;
    width: 100%;
    height: 100%;
    font-size: 13px;
    
}
#root {
    width: 100%;
    height: 100%;
}
a {
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none !important;
    }
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header2 {
    font-size: 18px;
    // color: $text-color;
}

.form {
    height: 100%;
    //max-height: calc(100% - 60px);
    // min-height: calc(100vh - 208px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button {
        margin-left: 10px;
    }
}

.spaceBtw {
    display: flex;
    justify-content: space-between;
    button {
        margin-left: 10px;
    }
}

.uploadedFiles {
    display: flex;
    align-items: center;
    margin-top: 20px;
    a {
        margin-left: 10px;
    }
}

.expandedPanel {
    margin: 30px 0;
    .MuiButtonBase-root.MuiExpansionPanelSummary-root {
        background-color: #f9f9f9;
    }
    .MuiExpansionPanelDetails-root {
        padding-top: 20px;
    }
}

.customError {
    margin: 0;
    font-size: 13px;
    color: red;
    text-align: center;
}

.textOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.warningMessage {
    font-size: 13px;
    // color: $text-color;
}

.menuLogo {
    height: 40px;
    img {
        height: 100%;
    }
}

.MuiMenu-list[role='listbox'] {
    max-height: 300px;
}

.testMessage {
    position: fixed;
    top: 0;
    z-index: 2;
    left: 390px;
    width: calc(100% - 670px);
    // color: red;
    display: flex;
    align-items: center;
    height: 60px;
}

td {
    word-wrap: break-word;
    a {
        word-break: break-word;
    }
}

.shortName {
    display: flex;
    a {
        margin-left: 5px;
    }
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 600px;
    }
}
button[class*="Step-selected-"] {
    color: #ffffff !important; /* Цвет текста */
   }
.highcharts-credits {
    display: none !important;
}
.ant-table-thead {
    .ant-table-cell {
        text-align: center;
    }
}
td{ 
    &.ant-table-cell  {
        &.table_prices_class {
            padding-right: 6px;
            text-align: end;
        }
    }
}
.ant-table-thead {
    .ant-table-cell {
        padding: 5px;
    }
}
.ant-table-tbody {
    .ant-table-row {
        .ant-table-cell {
            padding: 5px;
            font-size: 8pt;
        }
    }
}
.ant-dropdown {
    z-index: 2000 !important;
}
.MuiTextField-root {
    width: 100%;
}
.MuiFormControl-root {
    width: 100%;
}
.fieldSearchWrapper{
    margin-bottom: 20px;
}
.directoryWrapper{
    width: 60%;
}
@media screen and (max-width: 768px){
    .directoryWrapper{
        width: 80%;
    }
}
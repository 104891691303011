@import '../../mixins/input.scss';
@import '../../mixins/button.scss';

.inputBlock {
    @include input;
    width: 415px;
}
.passwordChangeVisibility {
    &:hover {
        cursor: pointer;
    }
}
.registrationHeader {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 14px;
}
.fullAuthBlock {
    border-radius: 14px;
    width: 200px;
    height: 300px;   
}
.mainFormButton {
    @include button_main;
    width: 100%;
    margin-top: 8px;
    display:flex;
    justify-content: center;
    // align-self: center;
}
.buttonAsText{
    @include buttonAsText;
    &:hover {
        cursor: pointer;
    }
}
.fullLoadScreen {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 3000;
    background: rgba(255, 255, 255, 0.767);
    display:flex;
    justify-content:center;
    align-items:center;
    position: absolute;
 
}